.title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.title {
  font-size: 14px;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
}

.more-icon {
  position: absolute;
  right: -10px;
  top: -10px;
  cursor: pointer;
}

.legend-more-button {
  display: flex;
  align-items: center;
  gap: 10px;
}

.legend-container {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-right: 40px;
}

.sub-title {
  font-size: 12px;
  color: var(--fg-color-greyed);
}
