.container {
  display: flex;
  flex-direction: column;
  padding: 1em;
}

.support-info-table {
  width: 100%;
}

.version-info-name,
.version-info-value {
  padding: 10px 0;
  vertical-align: text-top;
  min-width: 150px;
  color: var(--fg-color-greyed);
}
.version-info-value {
  padding-left: 0.1em;
  word-wrap: break-word;
  white-space: pre-wrap;
  overflow-wrap: anywhere;
}

.title {
  font-size: 1.5em;
}
