.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 16px;
  width: 100%;
}
