/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.remove-space {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  margin: 0;
  padding: 0;
}

.list-header {
  font-weight: bold;
  margin-bottom: 0.1em;
}
