.container {
  grid-row: 1;
  grid-column: 1;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: auto minmax(0, 1fr);
  background-color: var(--bg-color-dark1);
  z-index: 9999;
}

.header {
  grid-row: 1;
  grid-column: 1;
  padding-top: 6px;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  justify-content: space-between;
  z-index: 20;
  position: relative;
}

.header.with-border::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 1.5em; /* Start the line 20px from the left */
  right: 1.5em; /* End the line 20px before the right */
  background-color: var(--border-color);
  height: 1px;
}

.btn-close {
  padding-left: 0;
}

.action-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.title {
  padding-left: 1em;
}

.border {
  position: absolute;
  top: 0;
  height: 20px;
  width: 100%;
  background: #2f3859;
}
