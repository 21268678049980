.title {
  font-size: 1.5em;
}

.chat-list-container {
  display: flex;
  flex-direction: column;
}

.chat-list {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
}

.button-container {
  display: flex;
  flex-direction: column;
  padding: 1em;
}
