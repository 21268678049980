.container {
  display: flex;
  flex-direction: column;
  background: rgba(18, 25, 48, 1);
  border: 1px solid rgba(48, 56, 89, 1);
  border-radius: 6px;
  padding: 18px;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  gap: 15px;
}
