/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  position: relative;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  height: 100%;
  width: 25px;
}

.container-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 80px;
  left: 0px;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container-icon:focus {
  outline: none !important;
}

.container-icon:active,
.container-icon:focus {
  background-color: transparent !important; /* Using !important to override any inline styles */
}

.container-icon-svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 7px;
  width: 20px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
