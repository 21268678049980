.container {
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 20px;
  gap: 30px;
  height: 100%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--bg-color-dark1);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  flex-direction: column;
}

.flex-1 {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.header-title {
  font-size: 20px;
  font-weight: 600;
}

.header-description {
  text-align: center;
  color: var(--fg-color-greyed);
  padding: 0 20px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 3em;
}

.problem-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  color: var(--fg-color-greyed);
  word-break: break-all;
}

.primary-text {
  color: var(--fg-color-frame);
  cursor: pointer;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.qrCode {
  background-color: white;
  border: 48px solid white;
}

.btn-close {
  position: absolute;
  top: 6px;
  right: 0;
  cursor: pointer;
}
