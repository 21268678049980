/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  font-size: 12px;
  color: rgb(174, 176, 189);
  margin-bottom: 10px;
}
