/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  position: relative;
  padding: 0.5em 0 0.5em 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
}

.plus-container {
  position: absolute;
  right: 16px;
  bottom: 16px;
  width: 54px;
  height: 54px;
  background-color: var(--bg-color-frame);
  border-radius: 30px;
  padding: 7px;
}
.plus {
  fill: var(--fg-color-bright);
}
