/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.pat-icon {
  fill: var(--fg-color-bright);
}
.icon {
  fill: #c7c7c7;
}

.icon-white {
  fill: #fff;
}

.id-icon {
  fill: var(--fg-color-bright);
  transform: translateY(1px);
}

.name {
  font-size: large;
}
.sub-meta-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 0.5em;
  height: 20px;
  color: rgba(255, 255, 255, 0.75);
}
.sex-info {
  display: flex;
  width: 20px;
  height: 20px;
}

.cave {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.dob-info {
  margin-right: 0.3em;
}
.icon-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.2em;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.3em;
}

.icon-id {
  display: flex;
  flex-direction: row;
  width: 20px;
  height: 20px;
}
