/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  grid-row: 2;
  grid-column: 1;
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 24px;
  overflow-y: auto;
}

.title {
  font-weight: bold;
  font-size: x-large;
}

.entry {
  font-weight: normal;
  /*width: 290px;*/
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.locked-entry {
  font-weight: normal;
  /*width: 290px;*/
  color: var(--fg-color-greyed);
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.permanent-container {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.lock-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5em;
}
.lock-icon {
  fill: var(--fg-color-frame);
}
.lock-text {
  font-size: large;
  color: var(--fg-color-bright);
}
.unlocked-text {
  font-size: large;
  color: var(--fg-color-frame);
}

.profile-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1em;
}
.profile {
  width: 60px;
  height: 60px;
}

.priority-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
