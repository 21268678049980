/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}

.tab {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
}

.tab-icon {
  fill: var(--fg-color-greyed);
  color: var(--fg-color-greyed);
}

.tab-active {
  height: 35px;
  width: 35px;
  fill: #fff;
  color: #fff;
}

.tab-content {
  padding: 10px;
  width: 100%;
}

.tab-label {
  margin: 0;
  padding: 0;
}

.overlay-messenger {
  position: absolute;
  top: 0;
  right: -400px;
  left: 0;
  background-color: black;
  z-index: 9;
}

.messenger-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  width: 400px;
  background-color: var(--fg-color-dark1);
}
