/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.shadow-container {
  width: 100%;
  height: 100%;
}
.inner-container {
  height: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow-y: auto;
}

.header-container {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  font-size: large;
}

.location-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.location {
  flex-grow: 1;
}
.search {
  width: 36px;
  height: 36px;
  fill: var(--fg-color-frame);
}

.option-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.option-title {
  width: 150px;
  flex-shrink: 0;
}

.button-container {
  margin: 0.6em 1.2em 1.2em 1.2em;
}
