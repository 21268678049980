/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  width: 100%;
  height: 100vh;
  min-height: 0;
  display: grid;
  grid-template-rows: auto 1fr;
}

.title {
  display: flex;
  flex-direction: column;
  margin: 0.5em;
  font-size: x-large;
}

.entries {
  border-right: 0.5px solid var(--border-color);
}
