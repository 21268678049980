/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.title {
  margin-top: calc(7vh - var(--padding-std));
  margin-left: 20px;
  margin-right: auto;
  margin-bottom: 0.4em;
  font-size: xx-large;
  font-weight: bold;
}

.description {
  margin-left: 20px;
  margin-right: var(--padding-std);
  margin-top: var(--padding-std);
  line-height: var(--line-height-desc);
  width: calc(100% - 2 * var(--padding-std));
}
span > .description {
  color: var(--fg-color-frame);
}

.bottombar {
  width: 75%;
  padding: 2em;
}
