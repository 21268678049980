/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.4em;
}
