/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  overflow-y: auto;
}
.sub-container {
  display: flex;
  flex-direction: column;
  padding: 8px;
  margin-bottom: auto;
  gap: 4px;
}

.entry {
  word-wrap: break-word;
}
.timestamp {
  color: var(--fg-color-greyed);
  font-size: small;
}
.error {
  color: var(--fg-color-female);
}
.warning {
  color: var(--fg-color-warning);
}
.debug {
  color: #ffffffc0;
}
.trace {
  color: var(--fg-color-greyed);
}
