/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  grid-column: 1;
  grid-row: 1;
  z-index: 1;
  display: grid;
  grid-template-rows: minmax(0, 1fr);
}

.about-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: var(--bg-color-dark1);
}

.logo {
  max-width: 30%;
  max-height: 30%;
  flex-grow: 1;
}

.logo-info {
  width: 48px;
  height: 48px;
}

.info-section-footer {
  flex-grow: 1;
}
.info-section {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.version {
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 0.5em;
}

.support-text {
  font-size: large;
}

.support-infos {
  text-decoration: underline;
  margin-bottom: 1em;
}

.version-info-heading {
  display: block;
  font-weight: bold;
  margin-top: 0.6em;
}

.top-spacer {
  flex: 1;
}

.middle-spacer {
  flex: 8;
}

.version-info-server {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: left;
  padding-top: 0.4em;
  padding-bottom: 0.2em;
}
.version-info-server-url {
  font-size: 70%;
}

.license {
  display: flex;
  flex-direction: column;
  gap: 0.8em;
  padding-bottom: 0.2em;
}

.license-info-heading {
  text-decoration: underline;
  cursor: pointer;
}

.copyrightcontainer {
  display: block;
  font-size: small;
  color: var(--fg-color-greyed);
}

.title {
  font-size: 1.5em;
}

.info-container {
  display: flex;
  flex-direction: column;
  padding: 0 1em;
  overflow: auto;
}

.info-section-text {
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding-bottom: 20px;
}
