/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.input-editor {
    pointer-events: none;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.drag-area {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 170;
  backdrop-filter: blur(10px);
}
