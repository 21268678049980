.thread-item-buttons {
  border-radius: var(--radius-corner-small);

  display: flex;
  flex-direction: column;
}

.thread-item-buttons .button svg {
  height: 2em;
}
.button-undo {
  padding: 0;
  margin-right: -8px;
}

.thread-item-undo {
  text-decoration: line-through;
}

.thread-item-stop {
}

.thread-item-border {
  width: 100%;
  padding-left: 0.5em;
  padding-right: 0.5em;
  padding-bottom: 0.1em;
  padding-top: 0.5em;

  grid-area: border;
  justify-self: center;
  align-self: center;

  display: flex;
  align-items: center;
  justify-content: center;
}

.sender {
  font-weight: bold;
  color: #42ba97;
  margin-bottom: 0.25em;
}

.recipient {
  font-weight: bold;
  color: #42ba97;
  margin-bottom: 0.25em;
  text-align: right;
}
.recipient::before {
  width: 100%;
  content: '->';
}

.content-area {
  display: flex;
  flex-direction: row;
}
.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--fg-color-bright);
  overflow-wrap: anywhere; /* let break long words ... note: 'break-word' did not have the right effect */
}

.response-options {
  display: flex;
  flex-direction: column;
  align-items: center; /* "height" along primary axis */
  justify-content: center; /* alignment on the primary axis */
  column-gap: 1em;
}

.response-options button {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.response-options button:not(:first-child) {
  background: none;
  border: 1pt solid var(--bg-color-frame);
  color: var(--bg-color-frame);
}

.response-options-disabled {
  opacity: 0.33;
}

.response {
  margin-top: 0.5em;
}

.response-content {
  padding-top: 0.3em;

  text-align: center;
  color: var(--fg-color-greyed);
  font-style: italic;
  font-weight: bold;

  display: flex;
  justify-content: center;
  align-items: center;
}
.response-content .button-undo svg {
  height: 30px;
}
.response .timestamp {
  font-size: smaller;
  color: var(--fg-color-bright2);
}

.countdown-timer {
  height: 4em;
}
.symbol-escalation {
  width: 48px;
  height: auto;
}

.status-line {
  display: flex;
  justify-content: right;

  font-size: smaller;
  color: var(--fg-color-bright2);

  margin-top: 3px;
}

.timestamp {
  text-align: right;
  font-style: italic;
}

.thread-item-status {
  padding-left: 0.3em;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--radius-corner-small);
  overflow: hidden;
  margin-bottom: 10px;
  cursor: pointer;
  width: 100%; /* Full width of the parent container */
  position: relative; /* Needed for the absolute positioning of the ::before pseudo-element */
  padding-top: 100%; /* Aspect ratio 1:1 */
}

.image-preview {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the container */
}


