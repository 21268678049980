/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 2px;
}

.text-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
}

.text {
  margin-left: 20px;
  margin-top: 16px;
  margin-bottom: 16px;
}
.active-text {
  margin-left: 20px;
  margin-top: 16px;
  margin-bottom: 16px;
  color: var(--fg-color-frame);
}
.problem-text {
  margin-left: 3px;
  margin-top: 16px;
  margin-bottom: 16px;
  color: var(--fg-color-warning);
}
.comment-text {
  margin-left: 20px;
  margin-bottom: 6px;
  color: var(--fg-color-greyed);
  font-size: smaller;
}
.icon-affirmative-text {
  margin-left: 4px;
  margin-bottom: 6px;
  color: var(--fg-color-frame);
}
.challenged-background {
  margin-left: 18px;
  margin-right: 16px;
  background-color: var(--bg-color-dark2);
  margin-bottom: 4px;
}
.icon-challenged-text {
  margin-left: 4px;
  margin-bottom: 6px;
  font-size: smaller;
  padding: 4px 2px 0px 2px;
}
.add-text {
  margin-left: 0.5em;
  color: var(--fg-color-frame);
}
.button {
  min-width: 260px;
  margin-left: 6px;
  margin-top: 6px;
  margin-bottom: 6px;
  padding-left: 10px;
  padding-right: 10px;
}
.custom-container {
  width: 280px;
  margin: 0 0 16px 0;
}

.icon-container {
  flex-shrink: 0;
  width: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
}

.icon {
  stroke: var(--fg-color-bright);
  fill: var(--fg-color-bright);
}
.active-icon {
  stroke: var(--fg-color-frame);
  fill: var(--fg-color-frame);
}
.problem-icon {
  flex-shrink: 0;
  fill: var(--fg-color-warning);
}
.doneall-icon-small {
  flex-shrink: 0;
  fill: var(--fg-color-frame);
  color: var(--fg-color-frame);
  scale: 0.8;
  margin-left: 0.7em;
  transform: translateY(-3px);
}
.doneall-icon-small-after {
  flex-shrink: 0;
  fill: var(--fg-color-frame);
  color: var(--fg-color-frame);
  scale: 0.75;
  margin-left: 6px;
  transform: translateY(-3px);
}
.problem-icon-small {
  flex-shrink: 0;
  scale: 0.85;
  margin-left: 2px;
  color: var(--fg-color-warning);
  fill: var(--fg-color-warning);
}

.line {
  flex: 1;
  border-left-color: var(--fg-color-bright);
}
.active-line {
  flex: 1;
  border-left-color: var(--fg-color-frame);
}
.problem-line {
  flex-shrink: 0;
  margin: 0 4px;
  width: 1.5em;
  border-top-color: var(--fg-color-warning);
}

.more-button {
  margin-left: 12px;
  fill: var(--fg-color-frame);
  /*stroke: var(--fg-color-frame);*/
  width: 32px;
  height: 32px;
  flex-shrink: 0;
}
.less-button {
  margin-left: 12px;
  fill: var(--fg-color-frame);
  /*stroke: var(--fg-color-frame);*/
  width: 32px;
  height: 32px;
  flex-shrink: 0;
}
.timestamp {
  margin-left: 20px;
  margin-bottom: auto;
  color: var(--fg-color-greyed);
  font-size: smaller;
}
.comment-text-timestamp {
  margin-left: 8px;
  margin-bottom: 6px;
  font-size: smaller;
}
.icon-affirmative-text-timestamp {
  margin-left: 8px;
  margin-bottom: 6px;
  color: var(--fg-color-frame);
  font-size: smaller;
}
