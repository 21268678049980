/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  position: relative;

  display: flex;
  flex-direction: row;
  gap: 1em;
  margin-top: 0.5em;
}

.label {
  font-size: small;
  color: var(--fg-color-greyed);
}

.beds {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--padding-small);
  flex: 1;
}

.bed {
  width: 40%;
  max-width: 4em;
  height: 1.2em;
  border-radius: 0.6em;
}

.solid {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.inset {
  font-size: small;
  color: var(--fg-color-greyed);
}

.split {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
}

.bed-current {
  grid-column: 1;
  grid-row: 1;
  border-radius: 0.5em 0 0 0.5em;
}

.bed-next {
  grid-column: 2;
  grid-row: 1;
  border-radius: 0 0.5em 0.5em 0;
}

.free {
  background-color: var(--bg-color-bright);
}
.free .inset {
  color: var(--fg-color-dark1);
}
.free-male {
  background-color: var(--bg-color-frame);
  color: var(--fg-color-dark1);
}
.free-male .inset {
  color: var(--fg-color-dark1);
}
.free-female {
  background-color: var(--fg-color-female);
  color: var(--fg-color-dark1);
}
.free-female .inset {
  color: var(--fg-color-dark1);
}
.free-diverse {
  background-color: var(--fg-color-warning);
}
.free-diverse .inset {
  color: var(--fg-color-dark1);
}
.occupied {
  border-style: solid;
  border-width: var(--outline-thickness-thin);
  color: var(--bg-color-bright);
}
.occupied-male {
  border-style: solid;
  border-width: var(--outline-thickness-thin);
  color: var(--fg-color-frame);
}
.occupied-female {
  border-style: solid;
  border-width: var(--outline-thickness-thin);
  color: var(--fg-color-female);
}
.occupied-diverse {
  border-style: solid;
  border-width: var(--outline-thickness-thin);
  color: var(--fg-color-warning);
}
.blocked {
  border-style: solid;
  border-width: var(--outline-thickness-thin);
  color: var(--fg-color-greyed);
}
.blocked-soft {
  background-color: var(--fg-color-greyed);
}
.blocked-medium {
  background-color: var(--bg-color-dark3);
}

.detail-info {
  position: absolute;
  top: 100%;
  width: 100%;
  left: 0;
  z-index: 1;

  border: solid 1pt var(--fg-color-frame-dark);
  border-radius: 4px;
  padding: var(--padding-std);

  background: rgba(var(--bg-color-dark1-rgb), 0.8);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);

  display: flex;
  flex-direction: column;
  gap: 1.3vmin;
}
.detail-info-container {
  display: flex;
  flex-direction: row;
}
.detail-info-meta {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.detail-info-meta-line {
  display: flex;
  flex-direction: row;
  font-size: small;
  position: relative;
  left: -4px;
}
.detail-info-additional {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 5px;
}

.icon {
  fill: var(--fg-color-bright);
  width: 40px;
  height: 40px;
}
.other-icon {
  fill: var(--fg-color-greyed);
  width: 40px;
  height: 40px;
}

.dob-info {
  color: #c7c7c7;
  font-size: 16px;
}

.name {
  font-weight: bold;
  font-size: 18px;
}

.blocked-container {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  gap: 8px;
}

.blocked-reason-title {
  font-weight: bold;
}

.blocked-reason {
  font-weight: normal;
}

.no-data {
  font-size: 16px;
  font-weight: bold;
}


.multi-pill-icon-container {
  width: 64px;
  height: 20px;
  position: relative;
}

.multi-pill-icon-second-wrapper {
  background-color: var(--fg-color-dark1);
  position: absolute;
  width: 80%;
  height: 80%;
  bottom: 0;
  right: 0;
  border-radius: 60px;
}
