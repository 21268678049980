.container {
  display: flex;
  flex-direction: column;
  padding: 1em;
  gap: 1em;
  overflow: auto;
}
.counter {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  color: var(--fg-color-greyed);
  font-size: small;
}

.text-field-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.radio-buttons {
  display: flex;
  gap: 1em;
  flex-direction: column;
}

.radio-cont {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
