/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.close-button {
  position: absolute;
  top: 0;
  right: 0;
  background-color: black;
  padding: 10px;
}

.image {
  width: 100%;
}

.container-chat-picture {
  cursor: pointer;
}
