.container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99;
  background: repeating-linear-gradient(
    -45deg,
    rgba(18, 23, 46, 0.3),
    rgba(18, 23, 46, 0.3) 10px,
    rgba(48, 56, 89, 0.3) 10px,
    rgba(48, 56, 89, 0.3) 20px
  );
  font-size: var(--xlarge-text);
  line-height: 90%;
  pointer-events: none;
}
