/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}

.procedure-header {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  padding: 0 1.5em 0 1.5em;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  z-index: 10;
  background-color: var(--bg-color-dark1);
}

.procedure-header::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  height: 1em;
  background: linear-gradient(
    to top,
    rgba(var(--bg-color-dark1-rgb), 0),
    rgba(var(--bg-color-dark1-rgb), 1)
  );
  z-index: -1;
  pointer-events: none;
}

.content {
  padding-bottom: 60px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: none;
}
.content-inner {
  padding: 0.5em 20px 20px 20px;
  grid-row: 1 / 2;
  grid-column: 1 / 2;
}

.content-addresses {
  padding: 0em 1.5em 0em 1.5em;
  height: 100%;
  grid-row: 1 / 2;
  grid-column: 1 / 2;
}

.icon-button {
  width: 25px;
  height: 25px;
  fill: white;
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 0.5em;
  padding-bottom: 10px;
  border-bottom: 1px solid #2f3859;
}
